import { gql } from "@apollo/client";

export const AVAILABLE_INGREDIENTS_QUERY = gql`
  query AvailableIngredientsQuery($query: String) {
    availableIngredients(query: $query) {
      id
      name
      eanCode
      price
      unitType
      multiplier
      packageWeight
      category {
        id
        name
      }
    }
  }
`;
