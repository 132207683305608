import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { ErrorInfo } from "../../../shared/ErrorInfo";
import { EditableDataGrid } from "../../../shared/table/EditableDataGrid";
import { REMOVE_RESOURCE_MUTATION } from "../../mutations/mutations";
import {
  INGREDIENT_CATEGORIES_QUERY,
  INGREDIENT_CATEGORY_UPSERT_MUTATION,
} from "./ingredient-categories.query";

export const IngredientCategoriesPage = () => {
  const { loading, error, data } = useQuery(INGREDIENT_CATEGORIES_QUERY);

  const [upsertIngredientCategory] = useMutation(
    INGREDIENT_CATEGORY_UPSERT_MUTATION
  );
  const [removeResource] = useMutation(REMOVE_RESOURCE_MUTATION);

  if (loading || data.loading) {
    return <CircularProgress />;
  }
  if (error) return <ErrorInfo error={error} />;

  const columns: GridColDef<(typeof data.ingredientCategories)[number]>[] = [
    {
      field: "name",
      headerName: "Nazwa",
      width: 150,
      editable: true,
    },
  ];

  return (
    <>
      <EditableDataGrid
        modelType="ingredientCategory"
        nameColumn="name"
        title="Kategorie składników"
        refetchQueries={["IngredientCategoriesQuery"]}
        onSave={(data, done, err) => {
          upsertIngredientCategory({
            variables: {
              id: !data.isNew ? data.id : undefined,
              name: data.name,
            },
            refetchQueries: ["IngredientCategoriesQuery"],
          })
            .then(done)
            .catch(err);
        }}
        onRemove={(data, done, err) => {
          removeResource({
            variables: {
              id: [data.id],
              type: "ingredientCategory",
            },
            refetchQueries: ["IngredientCategoriesQuery"],
          })
            .then(done)
            .catch(err);
        }}
        rows={data.ingredientCategories}
        columns={columns}
        checkboxSelection={false}
      />
    </>
  );
};
