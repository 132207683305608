import { Check } from "@mui/icons-material";
import { Box } from "@mui/system";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { formatPrice, formatWeight } from "../../utils";

export const renderDate = (params, f = "dd/MM/yyyy") => {
  if (!params.value) {
    return "";
  }

  return format(new Date(params.value), f);
};

export const days_between = (date1, date2) => {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = date1 - date2;

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
};

export const noValueRenderer = () => <Box sx={{ color: "gray" }}>-</Box>;

export const booleanRenderer = (params) =>
  params.row.soldInUnits ? (
    <Check sx={{ color: "#333", fontSize: "14px" }} />
  ) : (
    <></>
  );

export const renderBoolean = (params: GridRenderCellParams) => {
  return booleanRenderer(params.value);
};

export const priceRenderer = (params: { value?: number }) => {
  if (!params.value) {
    return noValueRenderer();
  }

  return (
    <Box sx={{ display: "flex" }}>
      {formatPrice(params.value)}{" "}
      <Box sx={{ color: "grey", textIndent: 2 }}>zł</Box>
    </Box>
  );
};

export const weightRenderer = (params: { value?: number }) => {
  return (
    <Box sx={{ display: "flex" }}>
      {formatWeight(params.value)}{" "}
      <Box sx={{ color: "grey", textIndent: 2 }}>kg</Box>
    </Box>
  );
};

export const percentageRenderer = (params: { value?: number }) => {
  return (
    <Box sx={{ display: "flex" }}>
      {params.value} <Box sx={{ color: "grey", textIndent: 2 }}>%</Box>
    </Box>
  );
};
