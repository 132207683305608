import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { IngredientUnit } from "@prisma/client";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { calculateFinalPrice } from "../../../../shared/calculations.fns";
import { ConfirmationDialog } from "../../../../shared/confirmation-dialog/ConfirmationDialog";
import {
  priceRenderer,
  weightRenderer,
} from "../../../../shared/table/renderers/renderCell";
import {
  formatMargin,
  formatPrice,
  formatWeight,
} from "../../../../shared/utils";
import { REMOVE_RESOURCE_MUTATION } from "../../../mutations/mutations";
import { ProductCategorySelect } from "../../product-category-select/product-category-select";
import { ProductCloneActionButton } from "../../product-clone-action-button/product-clone-action-button.component";
import { ProductIngredientsTable } from "../../product-ingredients-table/product-ingredients-table.component";
import { UPSERT_PRODUCT } from "../product.query";

export const ProductSummaryTab = (props) => {
  const [isDisabled, setDisabled] = useState(!props.editMode);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [updateProduct] = useMutation(UPSERT_PRODUCT);
  const [removeResource] = useMutation(REMOVE_RESOURCE_MUTATION);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState,
  } = useForm({
    defaultValues: {
      ...props.product,
    },
  });
  const [tableState, setTableState] = useState([]);
  const editorRef = useRef(null);
  const tableRef = useRef(null);
  const [tableKey, setTableKey] = useState(0);
  const [checked, setChecked] = useState(
    props.product.soldInUnits ? true : false
  );
  const [saving, setSaving] = useState(false);

  const isManualPrice =
    getValues("manualPrice") !== "" && getValues("manualPrice") != null;

  const handleSave = async () => {
    setSaving(true);
    handleSubmit(async (data) => {
      const tableData = Object.entries(
        tableRef.current.current.getEditRowsModel()
      ).map((row: [string, any]) => {
        return {
          id: row[0],
          amount: parseFloat(row[1].amount.value),
          ingredientId: row[1].ingredient.value.id,
        };
      });

      console.log(tableData);

      updateProduct({
        variables: {
          id: data.id,
          eanCode: data.eanCode,
          name: data.name,
          categoryId: data.category.id,
          weightBeforeCooking: parseFloat(data.weightBeforeCooking),
          weightWhenReady: parseFloat(data.weightWhenReady) ?? 1,
          weightAfterOneDay: parseFloat(data.weightAfterOneDay) ?? 1,
          manualPrice: parseFloat(data.manualPrice),
          manualMargin: parseFloat(data.manualMargin),
          finalPrice: parseFloat(data.finalPrice),
          productionCost: parseFloat(data.productionCost),
          soldInUnits: checked,
          unitWeight: parseFloat(data.unitWeight) ?? 1,
          recipeText: editorRef.current.getContent(),
          validForDays: parseInt(data.validForDays),
          ingredients: tableData,
        },
        refetchQueries: ["ProductIngredientsQuery", "ProductQuery"],
      }).then((resp) => {
        if (props.editMode) {
          navigate(`/products/${resp.data.upsertProduct.id}`, {
            replace: true,
          });
        }
      });
      setDisabled(true);
      setSaving(false);
    })();
  };

  const calculateTotalIngredientsCost = (rows: any) => {
    const totalIngredientsCost = getTotalIngredientsCost(rows);
    const margin =
      getValues("manualMargin") !== "" && getValues("manualMargin") != null
        ? getValues("manualMargin")
        : getValues("category")?.margin;

    setValue(
      "totalIngredientsCost",
      formatPrice(getTotalIngredientsCost(rows))
    );

    setValue(
      "finalPrice",
      formatPrice(calculateFinalPrice(totalIngredientsCost, margin))
    );
  };

  const getTotalIngredientsCost = (rows: any) => {
    let totalIngredientsCost = 0;
    rows.forEach((row: any) => {
      let amount = parseFloat(
        row.amount?.value ? row.amount.value : row.amount
      );

      if (isNaN(amount)) {
        amount = 0;
      }

      const price =
        parseFloat(
          row.ingredient?.value?.price
            ? row.ingredient.value.price
            : row.ingredient.price
        ) ?? 1;

      totalIngredientsCost += amount * price;
    });

    return (totalIngredientsCost =
      (totalIngredientsCost + parseFloat(getValues("productionCost"))) /
      parseFloat(getValues("weightWhenReady")));
  };

  const calculateIngredientsWeightAndTotalPrice = (rows: any) => {
    let weightBeforeCooking = 0;
    rows.forEach((row: any) => {
      let amount = parseFloat(
        row.amount?.value ? row.amount.value : row.amount
      );

      if (isNaN(amount)) {
        amount = 0;
      }

      const multiplier = parseFloat(
        row.ingredient?.value?.multiplier ? row.ingredient.value.multiplier : 1
      );
      const packageWeight = parseFloat(
        row.ingredient?.value?.packageWeight
          ? row.ingredient.value.packageWeight
          : 1
      );
      const unitType =
        row.ingredient?.value?.unitType ?? row.ingredient?.unitType;

      if (unitType === IngredientUnit.SZT) {
        weightBeforeCooking += amount * packageWeight;
      } else {
        weightBeforeCooking += amount * multiplier;
      }
    });

    if (isNaN(weightBeforeCooking)) {
      weightBeforeCooking = 0;
    }

    setValue("weightBeforeCooking", formatWeight(weightBeforeCooking));
    setValue("weightWhenReady", formatWeight(weightBeforeCooking));
    setValue("weightAfterOneDay", formatWeight(weightBeforeCooking));

    calculateTotalIngredientsCost(rows);
  };

  function isAllValid(): boolean {
    const allAmountsValid = tableState.every((row) => {
      const amount = row.amount?.value ? row.amount.value : row.amount;
      return !isNaN(parseFloat(amount));
    });

    return formState.isValid && tableState.length > 0 && allAmountsValid;
  }

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ p: 2, pt: 0 }}>
      <ConfirmationDialog
        title="Usuwanie produktu"
        open={confirmationOpen}
        text={props.product.name}
        onYes={() => {
          removeResource({
            variables: {
              id: [props.product.id],
              type: "product",
            },
            refetchQueries: [],
          }).then(() => {
            setConfirmationOpen(false);
            navigate(`/products`, {
              replace: true,
            });
          });
        }}
        onNo={() => {
          setConfirmationOpen(false);
        }}
      />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mb: 1, gap: 1 }}
          >
            {isDisabled && (
              <>
                <Button
                  data-cy="project-pdf-button"
                  onClick={() => {
                    window.open(`/karty?id=${props.product.id}`, "_blank");
                  }}
                  variant="outlined"
                >
                  Drukuj
                </Button>
                <ProductCloneActionButton product={props.product} />
              </>
            )}
            {isDisabled && (
              <Button
                data-cy="project-edit-button"
                onClick={() => {
                  setTableKey(tableKey + 1);
                  setDisabled(false);
                }}
                variant="contained"
                sx={{ mr: 1 }}
              >
                Edytuj
              </Button>
            )}
            {isDisabled && (
              <Button
                onClick={() => {
                  setConfirmationOpen(true);
                }}
                color="error"
                variant="outlined"
              >
                Usuń
              </Button>
            )}
            {!isDisabled && (
              <>
                <LoadingButton
                  data-cy="project-save-button"
                  loading={saving}
                  disabled={!isAllValid()}
                  onClick={() => {
                    handleSave();
                  }}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  Zapisz
                </LoadingButton>

                <Button
                  data-cy="project-cancel-button"
                  onClick={() => {
                    if (props.editMode) {
                      navigate(`/products`, {
                        replace: true,
                      });
                    } else {
                      window.location.reload();
                    }
                  }}
                  variant="outlined"
                  sx={{ mr: 1 }}
                >
                  Anuluj
                </Button>
              </>
            )}
          </Box>
        </Grid>

        {!isDisabled && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name={"name"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    error={invalid}
                    placeholder="Nazwa produktu"
                    helperText={invalid ? "Nazwa wymagana" : ""}
                    required
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                    onKeyDown={(e) => (e.key === "Enter" ? handleSave() : null)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={"eanCode"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    required
                    error={invalid}
                    placeholder="EAN"
                    helperText={invalid ? "EAN wymagany" : ""}
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                    onKeyDown={(e) => (e.key === "Enter" ? handleSave() : null)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={"category"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <ProductCategorySelect
                    onChange={(e) => {
                      onChange(e);
                      calculateTotalIngredientsCost(tableState);
                    }}
                    value={value?.id}
                    error={invalid}
                    disabled={isDisabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {isDisabled && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
            <Box>
              <Typography variant="h5">{props.product.name}</Typography>
              <Box sx={{ color: "#808080c4" }}>{props.product.eanCode}</Box>
            </Box>
            <Chip variant="outlined" label={props.product.category.name}></Chip>
          </Box>
        )}
      </Grid>

      <ProductIngredientsTable
        productId={props.product.id}
        editMode={!isDisabled}
        onInit={(apiRef) => {
          tableRef.current = apiRef;
        }}
        onLoaded={(rows) => {
          setTableState(rows);
          calculateTotalIngredientsCost(rows);
        }}
        onRowEditCommit={(rows) => {
          setTableState(rows);
          calculateIngredientsWeightAndTotalPrice(rows);
        }}
        onEnterPress={() => {
          handleSave();
        }}
      />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box>
            <Box>Ilość produktu przed obróbką:</Box>
            {isDisabled &&
              weightRenderer({ value: props.product.weightBeforeCooking })}
            {!isDisabled && (
              <Box sx={{ mt: 1 }}>
                <Controller
                  name={"weightBeforeCooking"}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid },
                  }) => (
                    <TextField
                      onChange={onChange}
                      value={formatWeight(value)}
                      required
                      error={invalid}
                      placeholder="Waga przed obróbką"
                      helperText={invalid ? "Waga wymagana" : ""}
                      size="small"
                      fullWidth
                      disabled={isDisabled}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">kg</InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? handleSave() : null
                      }
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Box>Ilość produktu po obróbce:</Box>
            {isDisabled &&
              weightRenderer({ value: props.product.weightWhenReady })}
            {!isDisabled && (
              <Box sx={{ mt: 1 }}>
                <Controller
                  name={"weightWhenReady"}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid },
                  }) => (
                    <TextField
                      onChange={(e) => {
                        onChange(e);
                        calculateTotalIngredientsCost(tableState);
                      }}
                      value={formatWeight(value)}
                      required={true}
                      error={invalid}
                      placeholder="Waga"
                      helperText={invalid ? "Waga wymagana" : ""}
                      size="small"
                      fullWidth
                      disabled={isDisabled}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">kg</InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? handleSave() : null
                      }
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Box>Ilość produktu po 1 dniu:</Box>
            {isDisabled &&
              weightRenderer({ value: props.product.weightAfterOneDay })}
            {!isDisabled && (
              <Box sx={{ mt: 1 }}>
                <Controller
                  name={"weightAfterOneDay"}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid },
                  }) => (
                    <TextField
                      onChange={onChange}
                      value={formatWeight(value)}
                      required
                      error={invalid}
                      placeholder="Waga"
                      helperText={invalid ? "Waga wymagana" : ""}
                      size="small"
                      fullWidth
                      disabled={isDisabled}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">kg</InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? handleSave() : null
                      }
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Tooltip
            title="Waga pojedynczej sztuki produktu"
            placement="bottom-start"
          >
            <Box>
              <Box sx={{ position: "relative", width: "200px" }}>
                Sprzedaż w sztukach:
                {isDisabled && (props.product.soldInUnits ? " Tak" : " Nie")}
                {!isDisabled && (
                  <Switch
                    sx={{ position: "absolute", right: "20px", bottom: "-1px" }}
                    size="small"
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
              </Box>
              {isDisabled &&
                props.product.soldInUnits &&
                weightRenderer({ value: props.product.unitWeight })}
              {!isDisabled && (
                <Box sx={{ mt: 1 }}>
                  <Controller
                    name={"unitWeight"}
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { invalid },
                    }) => (
                      <TextField
                        onChange={onChange}
                        value={formatWeight(value)}
                        required
                        error={invalid}
                        placeholder="Waga"
                        helperText={invalid ? "Waga wymagana" : ""}
                        size="small"
                        fullWidth
                        disabled={isDisabled || !checked}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                        onKeyDown={(e) =>
                          e.key === "Enter" ? handleSave() : null
                        }
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={3}>
          Koszty produkcji:
        </Grid>
        <Grid item xs={3}>
          {isDisabled && priceRenderer({ value: props.product.productionCost })}
          {!isDisabled && (
            <Box sx={{ mt: 1 }}>
              <Controller
                name={"productionCost"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={(e) => {
                      onChange(e);
                      calculateTotalIngredientsCost(tableState);
                    }}
                    value={value}
                    error={invalid}
                    required
                    placeholder="Koszt"
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">zł</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) => (e.key === "Enter" ? handleSave() : null)}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          Koszt 1kg gotowego produktu:
        </Grid>
        <Grid item xs={3}>
          {isDisabled &&
            priceRenderer({ value: getValues("totalIngredientsCost") })}
          {!isDisabled && (
            <Box sx={{ mt: 1 }}>
              <Controller
                name={"totalIngredientsCost"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    error={invalid}
                    size="small"
                    fullWidth
                    disabled={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">zł</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) => (e.key === "Enter" ? handleSave() : null)}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={3}>
          Marża:
        </Grid>
        <Grid item xs={3}>
          {isDisabled && (
            <>
              {props.product.manualMargin != null &&
              props.product.manualMargin !== 0 ? (
                <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                  <Box>{formatMargin(props.product.manualMargin)} %</Box>
                  <Box sx={{ fontSize: "10px", color: "grey" }}>
                    {formatMargin(props.product.category.margin)} %
                  </Box>
                </Box>
              ) : (
                <Box>
                  {formatMargin(props.product.category.margin)} %
                  <Box sx={{ color: "grey", display: "inline", ml: 1 }}>
                    ({props.product.category.name})
                  </Box>
                </Box>
              )}
            </>
          )}
          {!isDisabled && (
            <Box sx={{ mt: 1 }}>
              <Controller
                name={"manualMargin"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={(e) => {
                      onChange(e);
                      setValue("manualPrice", "");
                      calculateTotalIngredientsCost(tableState);
                    }}
                    value={formatMargin(value)}
                    error={invalid}
                    placeholder={formatMargin(watch("category")?.margin)}
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) => (e.key === "Enter" ? handleSave() : null)}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 1,
          display: "flex",
          alignItems: "baseline",
          fontWeight: isManualPrice ? "normal" : "bolder",
        }}
      >
        <Grid item xs={3}>
          Cena sprzedaży za 1kg:
        </Grid>
        <Grid item xs={3}>
          {priceRenderer({
            value: watch("finalPrice"),
          })}
        </Grid>
        <Grid item xs={3}></Grid>
        {checked && (
          <Grid item xs={3} sx={{ display: "flex", gap: 2 }}>
            Cena sprzedaży za 1 szt:{" "}
            {priceRenderer({
              value: watch("finalPrice") * watch("unitWeight"),
            })}
          </Grid>
        )}
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "baseline",
          fontWeight: isManualPrice ? "bolder" : "normal",
        }}
      >
        <Grid item xs={3}>
          Cena manualna:
        </Grid>
        <Grid item xs={3}>
          {isDisabled && priceRenderer({ value: getValues("manualPrice") })}
          {!isDisabled && (
            <Box sx={{ mt: 1 }}>
              <Controller
                name={"manualPrice"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={(e) => {
                      onChange(e);
                      setValue("manualMargin", "");
                      calculateTotalIngredientsCost(tableState);
                    }}
                    value={value}
                    error={invalid}
                    size="small"
                    fullWidth
                    placeholder="-"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">zł</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) => (e.key === "Enter" ? handleSave() : null)}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <Grid
        item
        xs={12}
        sx={{
          ".tox-tinymce": {
            borderRadius: "4px",
            border: "1px solid rgb(0 0 0 / 24%)",
          },
        }}
      >
        <Typography variant="h6" sx={{ pb: 1, mt: 3 }}>
          Przepis
        </Typography>
        {isDisabled && (
          <Box
            data-cy="product-recipe"
            dangerouslySetInnerHTML={{
              __html: props.product.recipeText || "-",
            }}
          ></Box>
        )}
        {!isDisabled && (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={props.product.recipeText}
            init={{
              menubar: false,
              autoresize_bottom_margin: 10,
              plugins: [
                "autoresize",
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "blocks bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat code | help",
            }}
          />
        )}
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center", mt: 2 }}
      >
        <Grid item xs={3}>
          Spożyć w ciągu:
        </Grid>
        <Grid item xs={3}>
          {isDisabled && props.product.validForDays + " dni"}
          {!isDisabled && (
            <Box sx={{ mt: 1 }}>
              <Controller
                name={"validForDays"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    error={invalid}
                    placeholder="Dni"
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">dni</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) => (e.key === "Enter" ? handleSave() : null)}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
