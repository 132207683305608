import { Box, Typography } from "@mui/material";


export const ErrorInfo = (props) => {
  return <Box >
    <Typography variant="h6" sx={{ color: "red" }}>Error loading data:</Typography>
    <Typography variant="body2">
      <pre>{ JSON.stringify(props.error, null, " ") }</pre>
    </Typography>
  </Box>;
}
