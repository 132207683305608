import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
} from "@mui/material";

export const LoadingBOM = () => {
  return (
    <Dialog open={true}>
      <DialogTitle>Importowanie pliku...</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Box sx={{ width: "400px", mb: 1, mt: 1 }}>
            <LinearProgress />
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
    </Dialog>
  );
};
