import { gql } from "@apollo/client";

export const INGREDIENT_CATEGORIES_QUERY = gql`
  query IngredientCategoriesQuery {
    ingredientCategories {
      id
      name
    }
  }
`;

export const INGREDIENT_CATEGORY_UPSERT_MUTATION = gql`
  mutation UpsertIngredientCategory($id: ID, $name: String) {
    upsertIngredientCategory(id: $id, name: $name) {
      id
      name
    }
  }
`;
