import { gql } from "@apollo/client";

export const PRODUCT_INGREDIENTS_QUERY = gql`
  query ProductIngredientsQuery($productId: ID!) {
    productIngredients(productId: $productId) {
      id
      ingredient {
        id
        name
        price
        unitType
        multiplier
        packageWeight
      }
      amount
      createdAt
      updatedAt
    }
  }
`;
