import { MenuItem, TextField } from "@mui/material";

interface IProps {
  name?: string;
  value?: string;
  onChange?: (e: any, ev: any) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  variant?: "standard" | "outlined";
}

export const IngredientUnitTypeSelect: React.FC<IProps> = (props) => {
  const units = ["KG", "SZT"];

  return (
    <TextField
      select
      fullWidth
      name={props.name}
      variant={props.variant}
      size="small"
      label={props.label}
      disabled={props.disabled}
      error={props.error}
      value={props.value}
      onChange={(ev) => props.onChange(ev, ev.target.value)}
    >
      {units.map((ingredientCategory) => (
        <MenuItem key={ingredientCategory} value={ingredientCategory}>
          {ingredientCategory}
        </MenuItem>
      ))}
    </TextField>
  );
};
