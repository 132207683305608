import { styled } from "@mui/material/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-row.manually-added": {
    backgroundColor: "#a3a3a312",
  },
  "& .MuiDataGrid-main > div:nth-child(2)": {
    height: "auto !important",
  },
  "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {
    backgroundColor: "#0072ff1a",
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  // "& .import-type-new": {
  //   backgroundColor: "#00ff000d !important;",
  // },
  // "& .import-type-changed": {
  //   backgroundColor: "#0066ff14 !important;",
  // },
  // "& .import-type-removed": {
  //   backgroundColor: "#ff000026 !important;",
  // },
  "& .cell-import-changed": {
    backgroundColor: "#00a1ff3b !important;",
  },
  "& .MuiDataGrid-row--editing": {
    boxShadow:
      "0px -1px 3px 1px #6161613b, 0px 2px 3px 0px #61616100, 0px 1px 3px 0px #6161617d",
  },
  "& .Mui-error": {
    backgroundColor: "#ffb2b2",
    color: "black !important",
  },
  "& .MuiDataGrid-editInputCell": {
    fontSize: "12px",
    textIndent: "2px",
    lineHeight: "12px",
  },
  //minHeight: 300,
  ".MuiSelect-select.MuiSelect-select": {
    boxSizing: "initial",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "rgb(106 153 199 / 8%)",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnSeparator--resizable": {
    cursor: "col-resize",
    touchAction: "none",
    zIndex: 99999999999,
    width: "3px",
    background: "#ededed",
    minHeight: "28px !important",
  },
  "& .MuiDataGrid-columnSeparator--resizable:hover": {
    background: "black",
  },
  "& .MuiDataGrid-columnSeparator--sideRight": {
    right: "-2px",
  },
  "& .MuiDataGrid-main>div:nth-child(3)": {
    display: "none",
  },
  // No border on right
  // '& .MuiDataGrid-row>div:last-child': {
  //   border: '0',
  // },
  // No blue outline on cell focus click

  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "#d0ffad21 !important",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiTextField-root": {
    width: "100%",
  },
}));
