import { Route, Routes } from "react-router-dom";
import { IngredientPage } from "../features/ingredients/ingredient-page/ingredient.page";
import { NewIngredientPage } from "../features/ingredients/ingredient-page/new-ingredient.page";
import { IngredientsPage } from "../features/ingredients/ingredients-page/ingredients.page";
import { LoginPage } from "../features/login/login.page";
import { IngredientCategoriesPage } from "../features/products/ingredient-categories-page/ingredient-categories.page";
import { ProductCategoriesPage } from "../features/products/product-categories-page/product-categories.page";
import { NewProductPage } from "../features/products/product-page/new-product.page";
import { ProductPage } from "../features/products/product-page/product.page";
import { ProductsPage } from "../features/products/products-page/products.page";
import App from "./App";

export const AppRoutes = () => (
  <Routes>
    <Route path="login" element={<LoginPage />} />
    <Route path="/" element={<App />}>
      <Route path="/" element={<ProductsPage />} />
      <Route path="products" element={<ProductsPage />} />
      <Route path="products/:id" element={<ProductPage />} />
      <Route path="new-product" element={<NewProductPage />} />
      <Route path="ingredients" element={<IngredientsPage />} />
      <Route path="ingredients/:id" element={<IngredientPage />} />
      <Route path="new-ingredient" element={<NewIngredientPage />} />
      <Route path="product-categories" element={<ProductCategoriesPage />} />
      <Route
        path="ingredient-categories"
        element={<IngredientCategoriesPage />}
      />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <h1>404 Brak strony lub brak uprawnień</h1>
          </main>
        }
      />
    </Route>
  </Routes>
);
