import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import {
  GridApi,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";

interface DataGridToolbarProps {
  apiRef: React.MutableRefObject<GridApi>;
  noAdding?: boolean;
  allowAddMany?: boolean;
  batchActionComponents?: any[];
  modelType: string;
  refetchQueries: string[];
  defaultNewRowData: any;
  resetSelection: () => void;
}

export const DataGridToolbar = (props: DataGridToolbarProps) => {
  const { apiRef } = props;

  const handleAddNewRowClick = () => {
    const id = Math.random().toString(36).substr(2, 9);
    apiRef.current.updateRows([
      { id, isNew: true, ...props.defaultNewRowData },
    ]);
    apiRef.current.setRowMode(id, "edit");
  };

  // Disable Add button if already adding new row.
  let isAlreadyAdding =
    apiRef.current
      .getAllRowIds()
      .map((r) => {
        return apiRef.current.getRowParams(r).row.isNew;
      })
      .filter((a) => a === true).length === 1;

  return (
    <Box
      sx={{ mb: 1, display: "flex", alignItems: "center" }}
      className="no-print"
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            alignSelf: "flex-start",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box sx={{ width: "200px" }}>
            <GridToolbarQuickFilter debounceMs={400} />
          </Box>
          <GridToolbarColumnsButton
            className="columns-button"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <GridToolbarFilterButton
            className="filter-button"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          {/* <GridToolbarExport
            className="export-button"
            csvOptions={{
              fileName: `${props.modelType}-${new Date().toLocaleString()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          /> */}
        </Box>
        <Button
          sx={{ mr: 1, visibility: props.noAdding ? "hidden" : "visible" }}
          color="primary"
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddNewRowClick}
          className="add-action"
          disabled={!props.allowAddMany && isAlreadyAdding}
        >
          Dodaj
        </Button>
      </Box>
      <Box sx={{ display: "flex", gap: "5px" }}>
        {props.batchActionComponents?.map((componentFactory, index) => {
          return (
            <Box
              data-cy="action-batch-button"
              sx={{ display: "inline-flex", whiteSpace: "nowrap" }}
              key={index}
            >
              {componentFactory({
                selected: apiRef.current.getSelectedRows(),
                resetSelection: props.resetSelection,
                modelType: props.modelType,
                refetchQueries: props.refetchQueries,
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
