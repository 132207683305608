import { gql } from "@apollo/client";

export const PRODUCTS_QUERY = gql`
  query ProductsQuery {
    products {
      id
      name
      eanCode
      finalPrice
      manualPrice
      soldInUnits
      unitWeight
    }
  }
`;
