export const formatQuantity = (quantity: number | string, precision = 2) => {
  let value = quantity;

  if (value === null || value === undefined) {
    return "";
  }

  if (typeof value === "string") {
    return value;
  }

  return value.toFixed(precision);
};

export const formatPrice = (quantity: number | string) => {
  return formatQuantity(quantity, 2);
};

export const formatWeight = (quantity: number | string) => {
  return formatQuantity(quantity, 3);
};

export const formatMargin = (quantity: number | string) => {
  return formatQuantity(quantity, 2);
};

export const generateUUID = () => {
  let d = new Date().getTime(),
    d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
};
