import CancelIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Grid, IconButton, Paper, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridApi,
  GridEventListener,
  GridEvents,
  GridGroupingColDefOverride,
  GridGroupingColDefOverrideParams,
  GridLinkOperator,
  GridRowOrderChangeParams,
  GridRowParams,
  GridSelectionModel,
  GridSortItem,
  GridValidRowModel,
  plPL,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { ConfirmationDialog } from "../confirmation-dialog/ConfirmationDialog";
import Title from "../Title";
import { DataGridToolbar } from "./DataGridToolbar";
import { renderDate } from "./renderers/renderCell";
import { StyledDataGrid } from "./StyledDataGrid";

interface IProps {
  sx?: any;
  noPaper?: boolean;
  title: string;
  columns: any;
  rows: any[];
  nameColumn: string;
  sortColumn?: GridSortItem;
  columnVisibility?: { [key: string]: boolean };
  noAdding?: boolean;
  allowAddMany?: boolean;
  noActions?: boolean;
  noIndexColumn?: boolean;
  checkboxSelection?: boolean;
  noDelete?: boolean;
  batchActionComponents?: any[];
  modelType: string;
  selectionModel?: GridSelectionModel;
  onSelectionChanged?: (newSelectionModel: GridSelectionModel) => void;
  editModeOn?: boolean;
  refetchQueries: string[];
  rowReordering?: boolean;
  isRowSelectable?: (params: GridRowParams) => boolean;
  defaultNewRowData?: any;
  pinnedColumns?: { left: string[]; right: string[] };
  isTreeData?: boolean;
  getTreeDataPath?: (row: any) => string[];
  defaultGroupingExpansionDepth?: number;
  groupingColDef?:
    | GridGroupingColDefOverride<GridValidRowModel>
    | ((
        params: GridGroupingColDefOverrideParams
      ) => GridGroupingColDefOverride<GridValidRowModel> | undefined | null);
  canEditRow?: (params: any) => boolean;
  canDeleteRow?: (params: any) => boolean;
  onRowOrderChange?: (
    params: GridRowOrderChangeParams,
    ev: any,
    api: any
  ) => void;
  onRowEditCommit?: (data: any) => void;
  onRowEditStop?: (params: any, event: any) => void;
  getRowClassName?: (params: any) => string;
  onRender?: (rows: any, api: React.MutableRefObject<GridApi>) => void;
  onSave: (data: any, done: () => void, err: (e: any) => void) => void;
  onRemove: (data: any, done: () => void, err: (e: any) => void) => void;
  onInit?: (api: React.MutableRefObject<GridApi>) => void;
}

export const isNavigationKey = (key: string) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

export const EditableDataGrid: React.FC<IProps> = (props) => {
  const apiRef = useGridApiRef();
  const [openSnackbar, setOpenSnackbar] = useState("");
  const [openErrSnackbar, setOpenErrSnackbar] = useState("");
  const [confirmationRemovalRowId, setConfirmationOpen] = useState(-1);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const handleRowEditStop: GridEventListener<GridEvents.rowEditStop> = async (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
    props.onRowEditStop && props.onRowEditStop(params, event);
  };

  const handleRowEditStart: GridEventListener<GridEvents.rowEditStop> = async (
    params,
    event
  ) => {
    event.defaultMuiPrevented =
      props.noActions || (props.canEditRow && !props.canEditRow(params.row));
  };

  const handleCellFocusOut: GridEventListener<GridEvents.cellFocusOut> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, "edit");
  };

  const handleSaveClick = (id) => async () => {
    const row = apiRef.current.getRow(id);

    const filterOutNonEnumValues = (data: object) => {
      const enumKeys = ["status"];

      enumKeys.forEach((key) => {
        if (data[key] && data[key] !== data[key].toUpperCase()) {
          delete data[key];
        }
      });

      return data;
    };

    if (!row.isSaved) {
      row.isSaved = true;

      // Wait for the validation to run
      const isValid = await apiRef.current.commitRowChange(id);
      if (isValid) {
        let changed: any = {};

        if (row.isNew) {
          // New row so send everything.
          changed = apiRef.current.getRow(id);
          changed.createdAt = new Date().toISOString();
        } else {
          // Updating, so extract only cells that did change.
          changed = apiRef.current.getRow(id);
        }

        changed = filterOutNonEnumValues(changed);

        props.onSave(
          {
            id: row.id,
            ...changed,
          },
          () => {
            row.isSaved = false;
            apiRef.current.setRowMode(id, "view");
            apiRef.current.updateRows([{ ...row, ...changed, isNew: false }]);
            setOpenSnackbar("Zapisany");
            setSelectionModel([]);
          },
          (err) => {
            row.isSaved = false;
            apiRef.current.updateRows([{ ...row }]);
            console.log(err);
            setOpenErrSnackbar(err.toString());
          }
        );
      }
    }
  };

  const handleDeleteClick = (id) => (event) => {
    event.stopPropagation();
    setConfirmationOpen(id);
  };

  const handleCancelClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, "view");

    const row = apiRef.current.getRow(id);
    if (row!.isNew) {
      apiRef.current.updateRows([{ id, _action: "delete" }]);
    }
  };

  const cols = [
    ...props.columns,

    {
      field: "createdAt",
      headerName: "Data dodania",
      type: "date",
      cellClassName: "print-hidden",
      headerClassName: "print-hidden",
      width: 160,
      renderCell: renderDate,
      editable: false,
    },
  ];

  if (!props.noActions) {
    cols.push({
      field: "actions",
      type: "actions",
      headerName: "Akcje",
      resizable: false,
      width: 100,
      cellClassName: "actions print-hidden",
      headerClassName: "actions print-hidden",
      filterable: false,
      getActions: ({ id }) => {
        const isInEditMode = apiRef.current.getRowMode(id) === "edit";
        const rowData = apiRef.current.getRow(id);

        if (rowData.isMaster) {
          return [];
        }

        if (isInEditMode) {
          return [
            <IconButton
              onClick={handleSaveClick(id)}
              className="save-action"
              color="primary"
            >
              <SaveIcon />
            </IconButton>,
            <IconButton
              //disabled={rowData.isSaved}
              className="textPrimary cancel-action"
              onClick={handleCancelClick(id)}
              color="inherit"
            >
              <CancelIcon />
            </IconButton>,
          ];
        }

        const actions = [];

        actions.push(
          <IconButton
            data-cy="edit-action"
            className="textPrimary edit-action"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={
              !(
                !props.canEditRow ||
                (props.canEditRow && props.canEditRow(rowData))
              )
            }
          >
            <EditIcon />
          </IconButton>
        );

        const canDelete = props.canDeleteRow
          ? props.canDeleteRow(rowData)
          : true;

        actions.push(
          <IconButton
            data-cy="delete-action"
            className="textPrimary delete-action"
            onClick={handleDeleteClick(id)}
            color="error"
            disabled={!canDelete || props.noDelete}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        );

        return actions;
      },
    });
  }

  let batchActions = props.batchActionComponents || [];

  if (!props.noIndexColumn) {
    cols.unshift({
      field: "index",
      headerName: "#",
      width: 20,
      filterable: false,
      resizable: false,
      renderCell: function (params) {
        return (
          <Box sx={{ color: "#959595" }}>
            {params.api.getRowIndex(params.row.id) + 1}
          </Box>
        );
      },
    });
  }

  useEffect(() => {
    if (props.onInit) {
      props.onInit(apiRef);
    }
  }, [props.onInit]);

  const editModeOn = props.editModeOn;

  useEffect(() => {
    let allRowIds = apiRef.current.getAllRowIds();
    if (editModeOn) {
      allRowIds.forEach((id) => {
        apiRef.current.setRowMode(id, "edit");
      });
    } else {
      allRowIds.forEach((id) => {
        apiRef.current.setRowMode(id, "view");
      });
    }
  }, [editModeOn, apiRef]);

  return (
    <React.Fragment>
      <Paper
        variant={props.noPaper ? "outlined" : "elevation"}
        sx={{
          p: props.noPaper ? 0 : 1,
          m: props.noPaper ? 0 : 1,
          mt: props.noPaper ? 0 : 1,
          border: props.noPaper ? "none" : "",

          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {props.title && (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Title>{props.title}</Title>
            </Grid>
          </Grid>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <StyledDataGrid
            sx={props.sx}
            disableVirtualization={true}
            treeData={props.isTreeData}
            getTreeDataPath={props.getTreeDataPath}
            defaultGroupingExpansionDepth={props.defaultGroupingExpansionDepth}
            rows={props.rows}
            columns={cols.map((col) => ({
              ...col,
              pinnable: false,
            }))}
            apiRef={apiRef}
            isRowSelectable={props.isRowSelectable}
            editMode="row"
            autoHeight={false}
            disableSelectionOnClick={true}
            density="compact"
            checkboxSelection={
              props.checkboxSelection !== undefined
                ? props.checkboxSelection
                : true
            }
            localeText={{
              ...plPL.components.MuiDataGrid.defaultProps.localeText,
              toolbarQuickFilterPlaceholder: "Szukaj...",
            }}
            onRowEditStop={handleRowEditStop}
            onRowEditStart={handleRowEditStart}
            onRowEditCommit={(data) => {
              if (props.onRowEditCommit) {
                props.onRowEditCommit(
                  Object.values(apiRef.current.getEditRowsModel())
                );
              }

              if (!props.noActions) {
                handleSaveClick(data)();
              }
            }}
            onCellFocusOut={handleCellFocusOut}
            getRowClassName={props.getRowClassName}
            rowReordering={props.rowReordering}
            onRowOrderChange={props.onRowOrderChange}
            rowHeight={40}
            groupingColDef={props.groupingColDef}
            pinnedColumns={
              props.pinnedColumns || {
                left: [
                  GRID_TREE_DATA_GROUPING_FIELD,
                  "actions2",
                  "status",
                  GRID_CHECKBOX_SELECTION_COL_DEF.field,
                ],
                right: ["actions", "actions3"],
              }
            }
            onSelectionModelChange={
              props.onSelectionChanged ||
              ((newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              })
            }
            selectionModel={props.selectionModel || selectionModel}
            components={{
              Toolbar: (p) =>
                DataGridToolbar({
                  ...p,
                  noAdding: props.noAdding,
                  allowAddMany: props.allowAddMany,
                  batchActionComponents: batchActions,
                  modelType: props.modelType,
                  refetchQueries: props.refetchQueries,
                  defaultNewRowData: props.defaultNewRowData,
                  resetSelection: () => setSelectionModel([]),
                }),
            }}
            componentsProps={{
              toolbar: {
                apiRef,
                printOptions: { disableToolbarButton: true },
              },
              filterPanel: {
                // Force usage of "And" operator
                linkOperators: [GridLinkOperator.And],
                // Display columns by ascending alphabetical order
                columnsSort: "asc",
                filterFormProps: {
                  // Customize inputs by passing props
                  linkOperatorInputProps: {
                    variant: "outlined",
                    size: "small",
                  },
                  columnInputProps: {
                    variant: "outlined",
                    size: "small",
                    sx: { mt: "auto" },
                  },
                  operatorInputProps: {
                    variant: "outlined",
                    size: "small",
                    sx: { mt: "auto" },
                  },
                  deleteIconProps: {
                    sx: {
                      "& .MuiSvgIcon-root": { color: "#d32f2f" },
                    },
                  },
                },
                sx: {
                  "& .MuiDataGrid-toolbarContainer": {
                    justifyContent: "flex-end",
                  },
                },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [
                  props.sortColumn !== undefined
                    ? props.sortColumn
                    : { field: "createdAt", sort: "asc" },
                ],
              },
              columns: {
                columnVisibilityModel: {
                  createdAt: false,
                  modifiedDate: false,
                  author: false,
                  orderedBy: false,
                  quantityType: false,
                  creationType: false,
                  ...props.columnVisibility,
                },
              },
            }}
          />
        </div>
      </Paper>

      <Snackbar
        open={!!openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar("")}
      >
        <Alert
          variant="filled"
          className="snackbar-ok"
          severity="success"
          data-cy="snackbar-success"
          sx={{ width: "100%" }}
        >
          {openSnackbar}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!openErrSnackbar}
        autoHideDuration={20000}
        data-cy="snackbar-error"
        onClose={() => setOpenErrSnackbar("")}
      >
        <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
          {openErrSnackbar}
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={confirmationRemovalRowId !== -1}
        text={
          confirmationRemovalRowId !== -1
            ? apiRef.current.getRow(confirmationRemovalRowId)[props.nameColumn]
            : ""
        }
        onYes={() => {
          const row = apiRef.current.getRow(confirmationRemovalRowId);

          props.onRemove(
            row,
            () => {
              //apiRef.current.updateRows([{ confirmationOpen: confirmationRemovalRowId, _action: 'delete' }]);
              setOpenSnackbar("Usunięty");
              setConfirmationOpen(-1);
              setSelectionModel([]);
            },
            (err) => {
              console.log(err);
              setOpenErrSnackbar(err.toString());
              setConfirmationOpen(-1);
            }
          );
        }}
        onNo={() => {
          setConfirmationOpen(-1);
        }}
      />
    </React.Fragment>
  );
};
