import { useQuery } from "@apollo/client";
import { CircularProgress, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { BackButton } from "../../../shared/BackButton";
import { ErrorInfo } from "../../../shared/ErrorInfo";
import { IngredientForm } from "./ingredient.form";
import { INGREDIENT_QUERY } from "./ingredient.query";

export const IngredientPage = () => {
  let params = useParams();
  const { loading, error, data } = useQuery(INGREDIENT_QUERY, {
    variables: {
      id: params.id,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }
  if (error) return <ErrorInfo error={error} />;

  return (
    <Paper
      sx={{
        p: 1,
        m: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <BackButton path="/ingredients" />
      <IngredientForm ingredient={data.ingredient} editMode={false} />
    </Paper>
  );
};
