import { useMutation, useQuery } from "@apollo/client";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Button, Chip, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { CURRENT_USER_QUERY, LOGOUT } from "./current-user-info.query";

export const CurrentUserInfo = () => {
  const { loading, data } = useQuery(CURRENT_USER_QUERY);
  const [logoutMutation] = useMutation(LOGOUT);

  const handleLogoutClick = () => {
    logoutMutation().then((res) => {
      localStorage.clear();
      window.location.reload();
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  // Logout user if local token is different than user's stored one
  if (!data || data?.currentUser === null) {
    window.location.href = "/login";
    return null;
  }

  return (
    <Box>
      <Chip
        sx={{ mr: 2, backgroundColor: "#ccf1ff" }}
        icon={<AccountCircleIcon />}
        label={data.currentUser.username}
      />
      <Button
        variant="contained"
        onClick={handleLogoutClick}
        startIcon={<LogoutOutlinedIcon />}
      >
        Wyloguj
      </Button>
    </Box>
  );
};
