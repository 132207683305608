import { gql, useMutation } from "@apollo/client";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
} from "@mui/material";
import { useRef, useState } from "react";
import { AffectedProductsPreviewTable } from "./affected-products-preview-table";
import { IngredientsPreviewTable } from "./ingredients-preview-table";
import { TransitionDown } from "./transition-down-animation";

interface IProps {
  onClose: () => void;
  fileName: string;
  ingredients: any;
  products: any;
}

const FINAL_IMPORT = gql`
  mutation (
    $items: [PreviewConfirmedItem]!
    $products: [PreviewConfirmedItem]!
  ) {
    finalImport(items: $items, products: $products)
  }
`;

export const ImportPreview: React.FC<IProps> = (props) => {
  const [finalImportMutation] = useMutation(FINAL_IMPORT);
  const [isImporting, setIsImporting] = useState(false);
  const [tab, setTab] = useState("0");
  const [selected, setSelected] = useState(props.ingredients.map((i) => i.id));
  const [selectedProducts, setSelectedProducts] = useState(
    props.products.map((i) => i.id)
  );
  const affectedProductstableRef = useRef(null);

  const affectedProductWithSelection = props.products.filter((p) => {
    return p.ingredientIds.some((id) => selected.includes(id));
  });

  const products = props.products.map((p) => {
    return {
      ...p,
      importType: affectedProductWithSelection.find((ap) => ap.id === p.id)
        ? p.importType
        : "nochange",
    };
  });

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      fullScreen
      TransitionComponent={TransitionDown}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Podgląd aktualizacji cen: {props.fileName}
        <Box sx={{ display: "flex", gap: 2 }}>
          <LoadingButton
            variant="contained"
            color="success"
            size={"large"}
            disabled={isImporting}
            loading={isImporting}
            onClick={() => {
              console.log(affectedProductstableRef.current);
              setIsImporting(true);
              finalImportMutation({
                variables: {
                  items: selected.map((id) => {
                    return {
                      id: id,
                      newPrice: props.ingredients.find((i) => i.id === id)
                        .newPrice,
                    };
                  }),
                  products: Object.entries(
                    affectedProductstableRef.current.current.getEditRowsModel()
                  )
                    .filter((data) => selectedProducts.includes(data[0]))
                    .map(
                      (
                        data: [string, { newPriceManual: { value: string } }]
                      ) => {
                        return {
                          id: data[0],
                          newPrice: parseFloat(data[1].newPriceManual.value),
                        };
                      }
                    )
                    .filter((p) => p.newPrice != null),
                },
                refetchQueries: ["IngredientsQuery"],
                onCompleted: () => {
                  props.onClose();
                },
              });
            }}
          >
            Aktualizuj
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={props.onClose}
            disabled={isImporting}
          >
            Zamknij
          </Button>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ m: -2, mt: 0, minWidth: 1100 }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_, v) => setTab(v)}>
                <Tab
                  sx={{ borderRight: 1, paddingRight: "35px" }}
                  label={
                    <>
                      <Badge
                        sx={{
                          position: "absolute",
                          right: "20px",
                          top: "15px",
                        }}
                        color="error"
                        badgeContent={selected.length}
                      ></Badge>
                      Składniki
                    </>
                  }
                  value="0"
                ></Tab>
                <Tab
                  sx={{ borderRight: 1, paddingRight: "35px" }}
                  label={
                    <>
                      <Badge
                        sx={{
                          position: "absolute",
                          right: "20px",
                          top: "15px",
                        }}
                        color="error"
                        badgeContent={selectedProducts.length}
                      ></Badge>
                      Zmienione produkty
                    </>
                  }
                  value="1"
                />
              </TabList>
            </Box>
            <TabPanel value="0">
              <IngredientsPreviewTable
                ingredients={props.ingredients}
                selectionModel={selected}
                onSelectionChanged={(sel) => {
                  setSelected(sel);
                }}
              />
            </TabPanel>
            <TabPanel value="1">
              <AffectedProductsPreviewTable
                products={products}
                selected={selected}
                onInit={(api) => {
                  affectedProductstableRef.current = api;
                }}
                selectionModel={selectedProducts}
                onSelectionChanged={(sel) => {
                  setSelectedProducts(sel);
                }}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
};
