import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, CircularProgress, Link } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ErrorInfo } from "../../../shared/ErrorInfo";
import { EditableDataGrid } from "../../../shared/table/EditableDataGrid";
import { priceRenderer } from "../../../shared/table/renderers/renderCell";
import { REMOVE_RESOURCE_MUTATION } from "../../mutations/mutations";
import { PRODUCTS_QUERY } from "./products.query";

export const ProductsPage = () => {
  const { loading, error, data } = useQuery(PRODUCTS_QUERY);
  const [removeResource] = useMutation(REMOVE_RESOURCE_MUTATION);
  const navigate = useNavigate();

  if (loading || data.loading) {
    return <CircularProgress />;
  }
  if (error) return <ErrorInfo error={error} />;

  const columns: GridColDef<(typeof data.products)[number]>[] = [
    { field: "eanCode", headerName: "EAN", width: 110 },
    {
      field: "name",
      headerName: "Nazwa",
      width: 250,
      editable: false,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Link component={RouterLink} to={`/products/${params.row.id}`}>
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "finalPrice",
      headerName: "Cena",
      width: 90,
      type: "number",
      renderCell: (params) => {
        if (!params.row.manualPrice) {
          if (params.row.soldInUnits) {
            return priceRenderer({
              value: params.row.finalPrice * params.row.unitWeight,
            });
          }
          return priceRenderer({ value: params.row.finalPrice });
        } else {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "black",
                  fontSize: "10px",
                  alignItems: "center",
                }}
              >
                •
              </Box>
              <Box sx={{ display: "flex" }}>
                {priceRenderer({ value: params.row.manualPrice })}
              </Box>
            </Box>
          );
        }
      },
    },
    {
      field: "soldInUnits",
      headerName: "kg/szt",
      editable: false,
      width: 50,
      type: "boolean",
      renderCell: (params) => {
        return (
          <Box sx={{ color: "grey" }}>
            {params.row.soldInUnits ? "szt" : "kg"}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <EditableDataGrid
        modelType="product"
        nameColumn="name"
        title="Produkty"
        refetchQueries={["ProductsQuery"]}
        batchActionComponents={[
          (toolbarProps) => (
            <Button
              variant="outlined"
              color="success"
              size="small"
              onClick={() => {
                navigate("/new-product");
              }}
              {...toolbarProps}
            >
              Nowy produkt
            </Button>
          ),
          (toolbarProps) => (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                window.open(`/karty`, "_blank");
              }}
              {...toolbarProps}
            >
              Drukuj karty
            </Button>
          ),
        ]}
        onSave={(data, done, err) => {}}
        onRemove={(data, done, err) => {
          removeResource({
            variables: {
              id: [data.id],
              type: "product",
            },
            refetchQueries: ["ProductsQuery"],
          })
            .then(done)
            .catch(err);
        }}
        noActions={true}
        noAdding={true}
        rows={data.products}
        columns={columns}
        checkboxSelection={false}
      />
    </>
  );
};
