import { gql } from "@apollo/client";

export const INGREDIENT_QUERY = gql`
  query IngredientQuery($id: ID!) {
    ingredient(id: $id) {
      id
      name
      eanCode
      price
      packageWeight
      multiplier
      isAllergen
      unitType
      allergensText
      category {
        id
        name
      }
    }
  }
`;

export const UPSERT_INGREDIENT = gql`
  mutation UpsertIngredient(
    $id: ID
    $name: String
    $eanCode: String
    $price: Float
    $isAllergen: Boolean
    $packageWeight: Float
    $multiplier: Float
    $unitType: String
    $categoryId: ID
    $allergensText: String
  ) {
    upsertIngredient(
      id: $id
      name: $name
      eanCode: $eanCode
      price: $price
      isAllergen: $isAllergen
      categoryId: $categoryId
      packageWeight: $packageWeight
      multiplier: $multiplier
      unitType: $unitType
      allergensText: $allergensText
    ) {
      id
      name
      eanCode
      price
      isAllergen
      packageWeight
      multiplier
      unitType
      category {
        id
        name
      }
      allergensText
    }
  }
`;
