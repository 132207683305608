import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { ErrorImport } from "./error-import-info";
import { ImportPreview } from "./import-preview";
import { LoadingBOM } from "./loading-import";

const FILE_UPLOAD = gql`
  mutation ($file: Upload!, $type: String!) {
    fileUpload(file: $file, type: $type) {
      error
      items {
        id
        eanCode
        name
        originalPrice
        newPrice
        diffType
        importType
      }
      productsAffected {
        id
        eanCode
        name
        originalCost
        newCost
        originalFinalPrice
        newFinalPrice
        manualPrice
        diffType
        ingredientIds
      }
    }
  }
`;

interface IProps {
  open: boolean;
  onClose?: () => void;
}

const Input = styled("input")({
  display: "none",
});

export const UpdatePricesImportDialog: React.FC<IProps> = (props) => {
  const [importMutation, { loading, error, data, reset }] =
    useMutation(FILE_UPLOAD);

  const [file, setFileName] = useState(null);

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setFileName(e.target.files[0]);
  };

  const handleImport = () => {
    importMutation({
      variables: {
        file: file,
        type: "append",
      },
    });
  };

  const handleClose = () => {
    reset();
    setFileName(null);
    props.onClose();
  };

  if (loading) return <LoadingBOM />;
  if (error) return <ErrorImport error={error} />;
  if (data) {
    if (data.fileUpload.error) {
      return (
        <ErrorImport
          error={data.fileUpload.error}
          skipped={data.fileUpload.skipped}
          onClose={handleClose}
        />
      );
    }

    return (
      <ImportPreview
        fileName={file.name}
        onClose={handleClose}
        ingredients={data.fileUpload.items}
        products={data.fileUpload.productsAffected}
      ></ImportPreview>
    );
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Aktualizacja cen składników</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          sx={{
            mb: 3,
            minWidth: 450,
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 3,
          }}
        >
          <label
            htmlFor="contained-button-file"
            style={{ display: "flex", width: "100%" }}
          >
            <Input
              accept=".xls,.xlsx"
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <Button
              variant="contained"
              component="span"
              color={file ? "success" : "info"}
              startIcon={<UploadFileIcon />}
              sx={{ flex: 1 }}
            >
              {file ? file.name : "Wybierz plik"}
            </Button>
          </label>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          disabled={!file}
          onClick={() => {
            handleImport();
          }}
        >
          OK
        </Button>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};
