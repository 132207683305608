import { ArrowLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IProps {
  path: string;
}

export const BackButton = (props: IProps) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="text"
      size="small"
      sx={{ width: "90px", position: "absolute" }}
      startIcon={<ArrowLeft />}
      onClick={() => {
        navigate(props.path);
      }}
    >
      Powrót
    </Button>
  );
};
