import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CLONE_PRODUCT } from "./product-clone-action-button.query";

export const ProductCloneActionButton = (props) => {
  const [cloneProduct] = useMutation(CLONE_PRODUCT);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Button
        data-cy="project-pdf-button"
        onClick={() => {
          setOpen(true);
        }}
        variant="outlined"
      >
        Klonuj
      </Button>
      <Dialog open={open}>
        <DialogTitle id="alert-dialog-title">
          {"Klonowanie produktu"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Czy napewno sklonować: " + props.product.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Anuluj</Button>
          <Button
            onClick={() => {
              cloneProduct({
                variables: {
                  id: props.product.id,
                },
              }).then((data) => {
                navigate(`/products/${data.data.cloneProduct.id}`, {
                  replace: true,
                });
              });
            }}
            autoFocus
          >
            Klonuj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
