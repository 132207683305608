import { gql } from "@apollo/client";

export const PRODUCT_QUERY = gql`
  query ProductQuery($id: ID!) {
    product(id: $id) {
      id
      name
      eanCode
      recipeText
      weightBeforeCooking
      weightWhenReady
      weightAfterOneDay
      manualPrice
      manualMargin
      finalPrice
      ingredientsCost
      soldInUnits
      unitWeight
      productionCost
      validForDays
      category {
        id
        name
        margin
      }
    }
  }
`;

export const UPSERT_PRODUCT = gql`
  mutation UpsertProduct(
    $id: ID
    $eanCode: String
    $name: String
    $recipeText: String
    $ingredients: [ProductIngredientInput]
    $weightBeforeCooking: Float
    $weightWhenReady: Float
    $weightAfterOneDay: Float
    $manualPrice: Float
    $manualMargin: Float
    $soldInUnits: Boolean
    $unitWeight: Float
    $productionCost: Float
    $validForDays: Int
    $categoryId: ID
  ) {
    upsertProduct(
      id: $id
      eanCode: $eanCode
      name: $name
      recipeText: $recipeText
      ingredients: $ingredients
      weightBeforeCooking: $weightBeforeCooking
      weightWhenReady: $weightWhenReady
      weightAfterOneDay: $weightAfterOneDay
      manualPrice: $manualPrice
      manualMargin: $manualMargin
      soldInUnits: $soldInUnits
      unitWeight: $unitWeight
      productionCost: $productionCost
      validForDays: $validForDays
      categoryId: $categoryId
    ) {
      id
      eanCode
      name
      recipeText
      weightBeforeCooking
      weightWhenReady
      weightAfterOneDay
      manualPrice
      manualMargin
      finalPrice
      soldInUnits
      unitWeight
      productionCost
      validForDays
      ingredientsCost
      category {
        id
        name
      }
    }
  }
`;
