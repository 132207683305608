import { gql } from "@apollo/client";

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      id
      username
    }
  }
`;

export const LOGOUT = gql`
  mutation {
    logout
  }
`;
