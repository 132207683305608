import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { TransitionDown } from "./transition-down-animation";

function errorComponent(
  error: String,
  skipped: { modelNumber: string; reason: string }[]
) {
  return (
    <Box sx={{ width: "600px", mb: 2, mt: 0 }}>
      {error === "INVALID_MIMETYPE" && (
        <>
          <b>Niepoprawny typ pliku</b>
          <br /> Tylko pliki Excel (.xls, .xlsx)
        </>
      )}
      {error === "NO_MATCHING_INGREDIENTS" && (
        <>
          <b>Brak pasujących składników</b>
          <br /> - Brak składników o podanym kodzie EAN w bazie danych
          <br /> - Brak zmian cen w pliku w stosunku do cen w bazie danych
        </>
      )}
    </Box>
  );
}

export const ErrorImport = (props) => {
  return (
    <Dialog fullScreen open={true} TransitionComponent={TransitionDown}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        Import: Błąd
        <Button
          variant="outlined"
          onClick={() => {
            props.onClose();
          }}
        >
          Zamknij
        </Button>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {errorComponent(props.error, props.skipped)}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
        >
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
};
