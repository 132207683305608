import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

interface IProps {
  open: boolean;
  title?: string;
  text?: string;
  onYes: () => void;
  onNo: () => void;
}

export const ConfirmationDialog: React.FC<IProps> = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-cy="remove-confirmation-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title || "Usuwanie"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Czy napewno usunąć: " + props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onNo}>Anuluj</Button>
          <Button onClick={props.onYes} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
