import { gql } from "@apollo/client";

export const PRODUCT_CATEGORIES_QUERY = gql`
  query ProductCategoriesQuery {
    productCategories {
      id
      name
      margin
    }
  }
`;

export const PRODUCT_CATEGORY_UPSERT_MUTATION = gql`
  mutation UpsertProductCategory($id: ID, $name: String, $margin: Float) {
    upsertProductCategory(id: $id, name: $name, margin: $margin) {
      id
      name
      margin
    }
  }
`;
