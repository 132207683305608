import { useMutation, useQuery } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { ErrorInfo } from "../../../shared/ErrorInfo";
import { EditableDataGrid } from "../../../shared/table/EditableDataGrid";
import { formatMargin } from "../../../shared/utils";
import { REMOVE_RESOURCE_MUTATION } from "../../mutations/mutations";
import {
  PRODUCT_CATEGORIES_QUERY,
  PRODUCT_CATEGORY_UPSERT_MUTATION,
} from "./product-categories.query";

export const ProductCategoriesPage = () => {
  const { loading, error, data } = useQuery(PRODUCT_CATEGORIES_QUERY);

  const [upsertProductCategory] = useMutation(PRODUCT_CATEGORY_UPSERT_MUTATION);
  const [removeResource] = useMutation(REMOVE_RESOURCE_MUTATION);

  if (loading || data.loading) {
    return <CircularProgress />;
  }
  if (error) return <ErrorInfo error={error} />;

  const columns: GridColDef<(typeof data.productCategories)[number]>[] = [
    {
      field: "name",
      headerName: "Nazwa",
      width: 150,
      editable: true,
    },
    {
      field: "margin",
      headerName: "Marża",
      width: 80,
      editable: true,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "baseline", gap: "1px" }}>
          {formatMargin(params.value)}
          <Box sx={{ fontSize: "10px", color: "gray", display: "inline" }}>
            %
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <>
      <EditableDataGrid
        modelType="productCategory"
        nameColumn="name"
        title="Kategorie produktów"
        refetchQueries={["ProductCategoriesQuery"]}
        onSave={(data, done, err) => {
          upsertProductCategory({
            variables: {
              id: !data.isNew ? data.id : undefined,
              name: data.name,
              margin: parseFloat(data.margin),
            },
            refetchQueries: ["ProductCategoriesQuery"],
          })
            .then(done)
            .catch(err);
        }}
        onRemove={(data, done, err) => {
          removeResource({
            variables: {
              id: [data.id],
              type: "productCategory",
            },
            refetchQueries: ["ProductCategoriesQuery"],
          })
            .then(done)
            .catch(err);
        }}
        rows={data.productCategories}
        columns={columns}
        checkboxSelection={false}
      />
    </>
  );
};
