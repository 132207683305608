import { Box } from "@mui/material";
import { EditableDataGrid } from "../../shared/table/EditableDataGrid";
import { priceRenderer } from "../../shared/table/renderers/renderCell";
import { formatPrice } from "../../shared/utils";

export const AffectedProductsPreviewTable = (props) => {
  const columns = [
    {
      field: "eanCode",
      headerName: "EAN",
      width: 110,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nazwa",
      width: 160,
      editable: false,
    },
    {
      field: "originalCost",
      headerName: "Akt. koszt produktów",
      width: 135,
      editable: false,
      type: "number",
      renderCell: priceRenderer,
    },
    {
      field: "newCost",
      headerName: "Nowy koszt produktów",
      type: "number",
      width: 135,
      editable: false,
      renderCell: priceRenderer,
    },
    {
      field: "diff",
      headerName: "Różnica",
      width: 80,
      editable: false,
      type: "number",
      renderCell: (params) => {
        if (params.row.importType === "nochange") {
          return "-";
        } else {
          const price = parseFloat(
            formatPrice(params.row.newCost - params.row.originalCost)
          );

          return (
            <Box
              sx={{
                display: "flex",
                color: price > 0 ? "red" : "green",
                flexDirection: "row",
              }}
            >
              <span>{price > 0 ? "+" : ""}</span>
              {priceRenderer({
                value: price,
              })}
            </Box>
          );
        }
      },
    },
    {
      field: "originalFinalPrice",
      headerName: "Akt. cena sprzedaży",
      width: 135,
      editable: false,
      type: "number",
      renderCell: priceRenderer,
    },
    {
      field: "newFinalPrice",
      headerName: "Nowa cena sprzedaży",
      width: 135,
      editable: false,
      type: "number",
      renderCell: priceRenderer,
    },
    {
      field: "manualPrice",
      headerName: "Akt. cena manualna",
      width: 135,
      editable: false,
      type: "number",
      renderCell: priceRenderer,
    },
    {
      field: "newPriceManual",
      headerName: "Nowa cena manualna",
      width: 135,
      editable: true,
    },
    {
      field: "newMarginManual",
      headerName: "Nowa marża manualna",
      width: 140,
      editable: true,
    },
  ];

  const productsSelectionModel = props.selectionModel.filter((id) => {
    return props.products
      .find((p) => p.id === id)
      .ingredientIds.some((iid) => {
        return props.selected.includes(iid);
      });
  });

  return (
    <EditableDataGrid
      onInit={(api) => {
        props.onInit(api);
      }}
      sx={{
        "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {
          backgroundColor: "white",
          borderTop: "0",
          borderBottom: "1px solid #80808038",
        },
        "& .MuiDataGrid-row--editing": {
          boxShadow: "none",
        },
        "& .MuiDataGrid-row.not-affected-row>.MuiDataGrid-cell": {
          backgroundColor: "white",
        },
        "& .MuiDataGrid-row.affected-row>.MuiDataGrid-cell": {
          backgroundColor: "rgba(25, 118, 210, 0.08)",
        },
      }}
      getRowClassName={(params) => {
        // selected ingredients are part of row.ingredientIds
        const isSelected = props.selected.find((id) => {
          return params.row.ingredientIds.includes(id);
        });

        if (isSelected) {
          return "affected-row";
        }
        return "not-affected-row";
      }}
      modelType="products"
      noPaper={true}
      nameColumn="name"
      title=""
      editModeOn={true}
      refetchQueries={[]}
      onSave={(data, done, err) => {}}
      onRemove={(data, done, err) => {}}
      rows={props.products}
      noActions={true}
      noAdding={true}
      columns={columns}
      checkboxSelection={true}
      selectionModel={productsSelectionModel}
      onSelectionChanged={props.onSelectionChanged}
    />
  );
};
