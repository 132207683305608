import { gql } from "@apollo/client";

export const INGREDIENTS_QUERY = gql`
  query IngredientsQuery {
    ingredients {
      id
      name
      eanCode
      price
      isAllergen
      packageWeight
      unitType
      multiplier
      category {
        id
        name
      }
    }
  }
`;
