export const calculateFinalPrice = (cost, margin) => {
  return cost / (1 - margin / 100);
};

export const calculateFinalPricePerKg = (ingredientsCost, productionCost, weightWhenReady, margin) => {
  let cost = ingredientsCost + productionCost;
  let priceOf1kg = cost / weightWhenReady;

  return calculateFinalPrice(priceOf1kg, margin);
};
