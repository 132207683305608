import CategoryIcon from "@mui/icons-material/Category";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Divider, List, styled } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { NavLink } from "react-router-dom";

const LinkSC = styled(NavLink)({
  textDecoration: "none",
  color: "black",
  "&.active > *": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
});

const LinkIconSC = styled(ListItemIcon)({
  minWidth: "36px",
});

export const NavBar = () => {
  return (
    <React.Fragment>
      <List component="nav">
        <LinkSC to="/">
          <ListItemButton>
            <LinkIconSC>
              <ListAltIcon />
            </LinkIconSC>
            <ListItemText primary="Produkty" />
          </ListItemButton>
        </LinkSC>
        <LinkSC to="ingredients">
          <ListItemButton>
            <LinkIconSC>
              <CategoryIcon />
            </LinkIconSC>
            <ListItemText primary="Składniki" />
          </ListItemButton>
        </LinkSC>
        <Divider sx={{ my: 2 }} />
        <LinkSC to="product-categories">
          <ListItemButton>
            <LinkIconSC>
              <CategoryOutlinedIcon />
            </LinkIconSC>
            <ListItemText primary="Kategorie produktów" />
          </ListItemButton>
        </LinkSC>
        <LinkSC to="ingredient-categories">
          <ListItemButton>
            <LinkIconSC>
              <CategoryOutlinedIcon />
            </LinkIconSC>
            <ListItemText primary="Kategorie składników" />
          </ListItemButton>
        </LinkSC>
      </List>
    </React.Fragment>
  );
};
