import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { BackButton } from "../../../shared/BackButton";
import { ErrorInfo } from "../../../shared/ErrorInfo";
import { PRODUCT_QUERY } from "./product.query";
import { ProductSummaryTab } from "./tabs/product-summary.tab";

export const ProductPage = () => {
  let params = useParams();
  const { loading, error, data } = useQuery(PRODUCT_QUERY, {
    variables: {
      id: params.id,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }
  if (error) return <ErrorInfo error={error} />;

  return (
    <Paper
      sx={{
        p: 1,
        m: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <BackButton path="/products" />
      <Box>
        <ProductSummaryTab product={data.product} editMode={false} />
      </Box>
    </Paper>
  );
};
