import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/material";
import { EditableDataGrid } from "../../shared/table/EditableDataGrid";
import { priceRenderer } from "../../shared/table/renderers/renderCell";
import { formatPrice } from "../../shared/utils";

export const IngredientsPreviewTable = (props) => {
  const columns = [
    {
      field: "eanCode",
      headerName: "EAN",
      width: 110,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nazwa",
      width: 150,
      editable: false,
    },
    {
      field: "originalPrice",
      headerName: "Aktualna cena",
      width: 100,
      editable: false,
      renderCell: priceRenderer,
      type: "number",
    },
    {
      field: "newPrice",
      headerName: "Nowa cena",
      width: 100,
      editable: false,
      renderCell: priceRenderer,
      type: "number",
    },
    {
      field: "diff",
      headerName: "Różnica",
      width: 80,
      editable: false,
      type: "number",
      renderCell: (params) => {
        if (params.row.importType === "nochange") {
          return "-";
        } else {
          const price = parseFloat(
            formatPrice(params.row.newPrice - params.row.originalPrice)
          );

          return (
            <Box
              sx={{
                display: "flex",
                color: price > 0 ? "red" : "green",
                flexDirection: "row",
              }}
            >
              <span>{price > 0 ? "+" : ""}</span>
              {priceRenderer({
                value: price,
              })}
            </Box>
          );
        }
      },
    },
    {
      field: "diffType",
      headerName: "Zmiana",
      width: 60,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {params.row.importType === "nochange" ? (
              <>-</>
            ) : params.value === "increase" ? (
              <KeyboardArrowUpIcon color="error" />
            ) : (
              <KeyboardArrowDownIcon color="success" />
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <EditableDataGrid
      modelType="ingredients"
      noPaper={true}
      nameColumn="name"
      title=""
      refetchQueries={[]}
      onSave={(data, done, err) => {}}
      onRemove={(data, done, err) => {}}
      rows={props.ingredients}
      noActions={true}
      noAdding={true}
      columns={columns}
      checkboxSelection={true}
      onSelectionChanged={props.onSelectionChanged}
      selectionModel={props.selectionModel}
    />
  );
};
