import { ApolloConsumer, useQuery } from "@apollo/client";
import {
  Autocomplete,
  Box,
  darken,
  lighten,
  styled,
  TextField,
} from "@mui/material";
import { Ingredient } from "@prisma/client";
import React, { useEffect } from "react";
import { AVAILABLE_INGREDIENTS_QUERY } from "./ingredients-select.query";

interface IProps {
  name?: string;
  value?: Ingredient;
  onChange?: (e: any, ev: any) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  variant?: "standard" | "outlined";
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "3px 10px 0px 10px",
  marginBottom: "4px",
  marginTop: "8px",
  //fontWeight: "bold",
  color: "#333",
  backgroundColor: lighten(theme.palette.grey[500], 0.85),
  ...theme.applyStyles("dark", {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export const IngredientSelect: React.FC<IProps> = (props) => {
  const [query, setQuery] = React.useState("");
  const [value, setValue] = React.useState<Ingredient | null>(props.value);
  const [options, setOptions] = React.useState([]);
  const { loading, error, data } = useQuery(AVAILABLE_INGREDIENTS_QUERY);

  const handleSearch = async ({ q, client }) => {
    setQuery(q);

    const { data } = await client.query({
      query: AVAILABLE_INGREDIENTS_QUERY,
      variables: { query: q },
    });
    setOptions(data.availableIngredients);
  };

  useEffect(() => {
    if (data) {
      setOptions(data.availableIngredients);
    }
  }, [data]);

  if (loading) return <div>...</div>;

  return (
    <ApolloConsumer>
      {(client) => (
        <Autocomplete
          sx={{ width: "100%" }}
          autoComplete
          size="small"
          noOptionsText="Nie znaleziono"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={(option) => option.category.name}
          getOptionLabel={(option: Ingredient) => option.name}
          options={options}
          onInputChange={(e, q) => handleSearch({ q, client })}
          inputValue={query}
          filterOptions={(x) => x}
          onChange={(event: any, newValue: Ingredient | null) => {
            setValue(newValue);
            props.onChange(event, newValue);
          }}
          value={value}
          renderInput={(params) => (
            <TextField fullWidth {...params} label="Składnik" />
          )}
          renderOption={(params, option) => (
            <Box
              sx={{
                fontSize: "0.8rem",
                margin: "-5px 0px -5px",
              }}
            >
              <li {...params}>{option.name}</li>
            </Box>
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />
      )}
    </ApolloConsumer>
  );
};
