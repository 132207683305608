import { Box, Grid, Paper } from "@mui/material";
import Title from "../../../shared/Title";
import { ProductSummaryTab } from "./tabs/product-summary.tab";

export const NewProductPage = () => {
  return (
    <Paper
      sx={{
        p: 1,
        m: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={1} sx={{ minWidth: 600 }}>
        <Grid item xs={6} className="project-name">
          <Title data-cy="project-title">Nowy produkt</Title>
        </Grid>
      </Grid>

      <Box>
        <ProductSummaryTab
          editMode={true}
          product={{
            id: "NEW",
            manualMargin: null,
            manualPrice: null,
            category: {},
            weightWhenReady: 1,
            weightAfterOneDay: 1,
            unitWeight: 1,
            validForDays: 1,
            productionCost: 0,
          }}
        />
      </Box>
    </Paper>
  );
};
